import classnames from 'classnames';

export default function Section({ className: classes = '', style,  withTopMargin = true, withSidePadding = true, isFullWidth, anchor: id, children }) {

	const className = classnames(
		'container',
		isFullWidth ? 'is-fullhd' : 'is-max-desktop',
		classes,
		withSidePadding ? 'px-3' : '',
		withTopMargin ? 'mt-6' : ''
	);

	return <section {...{ id, className, style }}>{children}</section>;
}