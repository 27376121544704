import { Fragment } from 'react';
import { Link } from 'gatsby';

import Section from '@wrappers/Section';

import { Instagram } from '@elements/Icon';

export default function Footer({ routes }) {
	return <footer className='has-background-black has-text-white mt-6 pt-5 pb-4'>
		<Section className='reset-anchors'>
			<Nav routes={routes} />
			<Copyright />
		</Section>
	</footer>;
}

function Nav({ routes }) {
	const navItemClassName = 'has-text-white py-2 px-1';
	return <nav>
		<ol className='is-flex is-justify-content-center is-flex-wrap-wrap gap-5' style={{ fontSize: '1.2rem' }}>
			{routes.map(({ slug, label, sub }) => {
				return <Fragment key={slug}>
					{sub ? null : <li style={{ lineHeight: 1 }}>
						<Link to={`/${slug}`} className={navItemClassName}>{label}</Link>
					</li>}
					{sub && sub.map(({ slug, label }) => {
						return <li key={slug} style={{ lineHeight: 1 }}>
							<Link to={`/${slug}`} className={navItemClassName}>{label}</Link>
						</li>;
					})}
				</Fragment>;
			})}
			<a href="https://instagram.com/tom_bezrukov" className="has-text-white" target="_blank" rel="noreferrer">
				<Instagram style={{ marginTop: '-2px' }} />
			</a>
		</ol>
	</nav>;
}

function Copyright() {
	return <div className='has-text-centered mt-6 mb-4'>
		<Link className='has-text-white' to="/">Tom Bezrukov</Link> © {(new Date()).getFullYear()}
	</div>;
}