import { createElement } from 'react';

import Page from "@config/Page";

export default function GatsbyPageWrapper({ element, props }) {
	return createElement(
		Page,
		props,
		element
	);
}