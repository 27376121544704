import { graphql, useStaticQuery } from 'gatsby';

import Component from './Header';

export default function Header() {

	const { allRoutesJson: { nodes: routes } } = useStaticQuery(graphql`
		query {
			allRoutesJson {
				nodes {
					slug
					label
					sub {
						slug
						label
					}
				}
			}
		}
	`);

	const props = {
		routes
	};

	return Component(props);
}