import { useState } from 'react';
import classNames from 'classnames';

import Section from '@wrappers/Section';

export default function Header({ routes }) {

	const [isOpened, setIsOpened] = useState(false);
	const toggleMenu = () => setIsOpened(!isOpened);
	const onClick = () => setIsOpened(false);

	const menuClasses = classNames('navbar-menu has-background-black reset-anchors', isOpened && 'is-active');
	const burgerClasses = classNames('navbar-burger has-text-white', isOpened && 'is-active');

	return <nav className="has-background-black">
		<Section withTopMargin={false}>
			<div className="navbar has-background-black reset-anchors">
				<div className="navbar-brand">
					<a className="navbar-item has-text-white is-uppercase has-text-weight-bold" href="/">
						Tom Bezrukov
					</a>

					<button onClick={toggleMenu} className={burgerClasses}>
						<span aria-hidden="true" />
						<span aria-hidden="true" />
						<span aria-hidden="true" />
					</button>
				</div>
				<div className={menuClasses}>
					<div className="navbar-end">
						{routes.map(({ slug, label, sub }) => {
							if (!sub) return <Link to={`/${slug}`} onClick={onClick} key={slug}>{label}</Link>;
							return <div key={slug} className="navbar-item has-dropdown is-hoverable">
								<span className="navbar-link has-background-black has-text-white has-text-weight-bold">
									{label}
								</span>
								<div className="navbar-dropdown has-background-black">
									{sub.map(({ slug, label }) => {
										return <Link to={`/${slug}`} onClick={onClick} key={slug}>{label}</Link>;
									})}
								</div>
							</div>;
						})}
					</div>
				</div>
			</div>
		</Section>
	</nav>;
}

function Link({ to, children, ...props }) {
	return <a className="navbar-item has-text-white has-text-weight-bold" {...props} href={to}>{children}</a>;
}